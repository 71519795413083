$sidebar-width: 200px;
$on-hover-color-li: #615609;
$sidebar-close-bar-width: 200px;

body {
  position: relative;
  overflow-x: hidden;
}

body,
html {
  height: 100%;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:active,
.nav .open > a:focus {
  background-color: transparent;
  color: #fff;
}

/*-------------------------------*/

/*           Wrappers            */

/*-------------------------------*/

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  .sidebar {
    width: $sidebar-close-bar-width;
    height: 100%;
    left: 0;
    position: fixed;
    background-color: $brand-primary;
    top: 0;
    // General hamburger alert
    //Set the message alert ( If messages are there)
    .message-alert {
      &.nomessage {
        .el-badge {
          &__content {
            // Hide work around if no messages are there
            background: transparent;
            color: transparent;
          }
        }
      }
      // Normal conditions
      .el-badge {
        &__content {
          &.is-fixed {
            top: 10px;
            right: -38px;
            z-index: 1000;
            opacity: 1;
            -webkit-transition: all 500ms ease-in-out;
            -moz-transition: all 500ms ease-in-out;
            -o-transition: all 500ms ease-in-out;
            transition: all 500ms ease-in-out;
          }
        }
      }
    }
  }
}

#wrapper.toggled {
  //margin-left: 230px;
  width: 100vw;
  .sidebar {
    width: $sidebar-close-bar-width;
    height: 100%;
    left: 70px;
    position: absolute;
    background-color: transparent;
    top: 0;
  }
  //Set the message alert ( If messages are there) fake hding it
  .message-alert {
    .el-badge {
      &__content {
        &.is-fixed {
          opacity: 0;
        }
      }
    }
  }
}

#sidebar-wrapper {
  z-index: 1000;
  left: $sidebar-width;
  width: 0;
  height: 100%;
  margin-left: -$sidebar-width;
  overflow-y: auto;
  overflow-x: hidden;
  background: $brand-primary;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

#wrapper.toggled #sidebar-wrapper {
  width: $sidebar-width;
}

#page-content-wrapper {
  width: 100%;
  height: auto;
}

#wrapper.toggled #page-content-wrapper {
  position: relative;
  height: 100%;
}

/*-------------------------------*/

/*     Sidebar nav styles        */

/*-------------------------------*/

.sidebar-nav {
  position: absolute;
  top: 0;
  width: $sidebar-width;
  margin: 0;
  padding: 0;
  list-style: none;
  // Badges for messages , if there is no message, then hide it
  .el-badge {
    &.nomessage {
      .el-badge {
        &__content {
          background: transparent;
          color: transparent;
        }
      }
    }
    &__content {
      &.is-fixed {
        right: 24px;
      }
    }
  }
}

.sidebar-nav .logo {
  margin: auto;
  width: 120px;
  display: block;
  padding-top: 2em;
  margin-bottom: 2em;
}

.sidebar-nav li {
  position: relative;
  line-height: 20px;
  display: inline-block;
  width: 100%;
  padding: .2em;
  span {
    padding: 1em;
    font-size: 1.2em;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.sidebar-nav li:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 3px;
  background-color: #1c1c1c;
  -webkit-transition: width .2s ease-in;
  -moz-transition: width .2s ease-in;
  -ms-transition: width .2s ease-in;
  transition: width .2s ease-in;
}

.sidebar-nav li:before {
  color: #333;
  background-color: $brand-secondary;
}

.sidebar-nav li:first-child a:before {
  font-weight: bold;
  background-color: transparent;
}

.sidebar-nav li:hover:before,
.sidebar-nav li.open:hover:before {
  width: 100%;
  -webkit-transition: width .1 ease-in;
  -moz-transition: width .1 ease-in;
  -ms-transition: width .1 ease-in;
  transition: width .1 ease-in;
}

.sidebar-nav li a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 10px 15px 10px 30px;
  margin-left: 2em;
  p {
    margin-left: 1em;
    margin-top: 7px;
    font-size: 17px;
  }
  svg {
    position: absolute;
    left: -6px;
    top: 10px;
  }

  &:hover {
    color: #333;

  }

  i {
    &.fa {
      font-size: 1.2em;
      margin-left: 1em;
      margin-right: 1em;
      width: 18px;
      text-align: center;
    }

    //&.fa {
    //  margin-left: 1em;
    //  margin-right: 1em;
    //  border-radius: 100%;
    //  border: 1px solid #333;
    //  width: 16px;
    //  text-align: center;
    //  color: #333;
    //  background: #fff;
    //}
  }
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus,
.sidebar-nav li.open a:active,
.sidebar-nav li.open a:focus {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  background-color: transparent !important;
}

.sidebar-nav li a:hover,
.sidebar-nav li.open a:hover {
  color: #333;
  font-weight: bold;
  text-decoration: none;
  background-color: transparent !important;
  svg {
    transition: 200ms ease all;
    -webkit-transition: 200ms ease all;
    transform: scale(1.2);

  }

  #logout {
    path:not(#arrow) {
      fill: #333 !important;
      transition: 1s ease fill;
      -webkit-transition: 1s ease fill;
    }

    path#arrow {
      fill: #b3b3b3 !important;
      transition: 1s ease all;
      -webkit-transition: 1s ease all;
      transform: rotate(180deg);
      transform-origin: 54% 50%;
    }
  }

  #settings {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    circle#bg {
      fill: #151515 !important;
      transition: 300ms ease fill;
      -webkit-transition: 300ms ease fill;
    }

    path#shadow {
      fill: #151515 !important;
      transition: 300ms ease fill;
      -webkit-transition: 300ms ease fill;
    }
  }

  #statistics {
    circle#bg {
      fill: #fff !important;
      transition: 1s ease fill;
      -webkit-transition: 1s ease fill;
    }

    path#table {
      fill: #000 !important;
      transition: 1s ease fill;
      -webkit-transition: 1s ease fill;
    }
  }

  #team {
    circle#bg {
      fill: #333 !important;
      transition: 1s ease fill;
      -webkit-transition: 1s ease fill;
    }

    g#person_bg_1 {
      circle {
        fill: #fff !important;
        transition: 1s ease fill;
        -webkit-transition: 1s ease fill;
      }
      path {
        fill: #fff !important;
        transition: 1s ease fill;
        -webkit-transition: 1s ease fill;
      }
    }

    g#person_bg_2 {
      circle {
        fill: #efefef !important;
        transition: 800ms ease fill;
        -webkit-transition: 800ms ease fill;
      }
      path {
        fill: #efefef !important;
        transition: 800ms ease fill;
        -webkit-transition: 800ms ease fill;
      }
    }

    g#person_bg_3 {
      circle {
        fill: #ccc !important;
        transition: 600ms ease fill;
        -webkit-transition: 600ms ease fill;
      }
      path {
        fill: #ccc !important;
        transition: 600ms ease fill;
        -webkit-transition: 600ms ease fill;
      }
    }

    g#person_bg_4 {
      circle {
        fill: #a3a3a3 !important;
        transition: 400ms ease fill;
        -webkit-transition: 400ms ease fill;
      }
      path {
        fill: #a3a3a3 !important;
        transition: 400ms ease fill;
        -webkit-transition: 400ms ease fill;
      }
    }
  }

  #training {
    path#sock {
      fill: #333 !important;
      transition: 400ms ease fill;
      -webkit-transition: 400ms ease fill;
    }

    path#sock_line {
      fill: #333 !important;
      transition: 400ms ease fill;
      -webkit-transition: 400ms ease fill;
    }

    path#sock_line {
      fill: #333 !important;
      transition: 400ms ease fill;
      -webkit-transition: 400ms ease fill;
    }

    path#field {
      fill: #333 !important;
      transition: 400ms ease fill;
      -webkit-transition: 400ms ease fill;
    }

    path#skybox {
      fill: #fff !important;
      transition: 200ms ease fill;
      -webkit-transition: 200ms ease fill;
    }

  }

  #player {
    polygon {
      fill: #b3b3b3 !important;
      transition: 1s ease fill;
      -webkit-transition: 1s ease fill;
    }

    path {
      fill: #3E4349 !important;
      transition: 200ms ease fill;
      -webkit-transition: 200ms ease fill;

    }

    rect {
      fill: #3E4349 !important;
      transition: 200ms ease fill;
      -webkit-transition: 200ms ease fill;

    }
  }
  #match {
    circle {
      fill: #333 !important;
      transition: 1s ease fill;
      -webkit-transition: 1s ease fill;
    }
  }
  p {
    color: #333;
  }
}

.sidebar-menu {
  margin-top: 160px;
  padding-top: 2em;

  li:hover:before {
    width: 100%;
    -webkit-transition: width .1 ease-in;
    -moz-transition: width .1 ease-in;
    -ms-transition: width .1 ease-in;
    transition: width .1 ease-in;
  }

  li {
    position: relative;
    line-height: 20px;
    display: inline-block;
    width: 100%;
    padding: 1em;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      height: 100%;
      width: 3px;
      background-color: $brand-secondary;
      -webkit-transition: width .2s ease-in;
      -moz-transition: width .2s ease-in;
      -ms-transition: width .2s ease-in;
      transition: width .2s ease-in;

    }

    &:hover {
      background-color: transparent !important;
      svg {
        transition: 200ms ease all;
        -webkit-transition: 200ms ease all;
        transform: scale(1.2);

      }

      #logout {
        path:not(#arrow) {
          fill: #333 !important;
          transition: 1s ease fill;
          -webkit-transition: 1s ease fill;
        }

        path#arrow {
          fill: #b3b3b3 !important;
          transition: 1s ease all;
          -webkit-transition: 1s ease all;
          transform: rotate(180deg);
          transform-origin: 54% 50%;
        }
      }

      #settings {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        circle#bg {
          fill: #151515 !important;
          transition: 300ms ease fill;
          -webkit-transition: 300ms ease fill;
        }

        path#shadow {
          fill: #151515 !important;
          transition: 300ms ease fill;
          -webkit-transition: 300ms ease fill;
        }
      }

      #statistics {
        circle#bg {
          fill: #fff !important;
          transition: 1s ease fill;
          -webkit-transition: 1s ease fill;
        }

        path#table {
          fill: #000 !important;
          transition: 1s ease fill;
          -webkit-transition: 1s ease fill;
        }
      }

      #team {
        circle#bg {
          fill: #333 !important;
          transition: 1s ease fill;
          -webkit-transition: 1s ease fill;
        }

        g#person_bg_1 {
          circle {
            fill: #fff !important;
            transition: 1s ease fill;
            -webkit-transition: 1s ease fill;
          }
          path {
            fill: #fff !important;
            transition: 1s ease fill;
            -webkit-transition: 1s ease fill;
          }
        }

        g#person_bg_2 {
          circle {
            fill: #efefef !important;
            transition: 800ms ease fill;
            -webkit-transition: 800ms ease fill;
          }
          path {
            fill: #efefef !important;
            transition: 800ms ease fill;
            -webkit-transition: 800ms ease fill;
          }
        }

        g#person_bg_3 {
          circle {
            fill: #ccc !important;
            transition: 600ms ease fill;
            -webkit-transition: 600ms ease fill;
          }
          path {
            fill: #ccc !important;
            transition: 600ms ease fill;
            -webkit-transition: 600ms ease fill;
          }
        }

        g#person_bg_4 {
          circle {
            fill: #a3a3a3 !important;
            transition: 400ms ease fill;
            -webkit-transition: 400ms ease fill;
          }
          path {
            fill: #a3a3a3 !important;
            transition: 400ms ease fill;
            -webkit-transition: 400ms ease fill;
          }
        }
      }

      #training {
        path#sock {
          fill: #333 !important;
          transition: 400ms ease fill;
          -webkit-transition: 400ms ease fill;
        }

        path#sock_line {
          fill: #333 !important;
          transition: 400ms ease fill;
          -webkit-transition: 400ms ease fill;
        }

        path#sock_line {
          fill: #333 !important;
          transition: 400ms ease fill;
          -webkit-transition: 400ms ease fill;
        }

        path#field {
          fill: #333 !important;
          transition: 400ms ease fill;
          -webkit-transition: 400ms ease fill;
        }

        path#skybox {
          fill: #fff !important;
          transition: 200ms ease fill;
          -webkit-transition: 200ms ease fill;
        }

      }

      #player {
        polygon {
          fill: #b3b3b3 !important;
          transition: 1s ease fill;
          -webkit-transition: 1s ease fill;
        }

        path {
          fill: #3E4349 !important;
          transition: 200ms ease fill;
          -webkit-transition: 200ms ease fill;

        }

        rect {
          fill: #3E4349 !important;
          transition: 200ms ease fill;
          -webkit-transition: 200ms ease fill;

        }
      }
      #match {
        circle {
          fill: #333 !important;
          transition: 1s ease fill;
          -webkit-transition: 1s ease fill;
        }
      }
    }
  }
}

a.active {
  color: $on-hover-color-li;
  font-weight: bold;
  text-decoration: none;
  background-color: transparent !important;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 20px;
  line-height: 44px;
  &:hover {
    background-color: transparent;
  }
}

.sidebar-nav .dropdown-menu {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: #222;
  box-shadow: none;
}

/*-------------------------------*/

/*       Hamburger-Cross         */

/*-------------------------------*/

.hamburger {
  position: fixed;
  top: 20px;
  z-index: 999;
  display: block;
  width: 32px;
  height: 32px;
  margin-left: 15px;
  background: transparent;
  border: none;
}

.hamburger:hover,
.hamburger:focus,
.hamburger:active {
  outline: none;
}

.hamburger.is-closed:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px, 0, 0);
  -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  position: absolute;
  left: 0;
  height: 4px;
  width: 100%;
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom {
  background-color: #fff;
}

.hamburger.is-closed .hamb-top {
  top: 5px;
  -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed .hamb-middle {
  top: 50%;
  margin-top: -2px;
}

.hamburger.is-closed .hamb-bottom {
  bottom: 5px;
  -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed:hover .hamb-top {
  top: 0;
  -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed:hover .hamb-bottom {
  bottom: 0;
  -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  background-color: $brand-primary;
}

.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
  top: 50%;
  margin-top: -2px;
}

.hamburger.is-open .hamb-top {
  -webkit-transform: rotate(45deg);
  -webkit-transition: -webkit-transform .1 cubic-bezier(.73, 1, .28, .08);
}

.hamburger.is-open .hamb-middle {
  display: none;
}

.hamburger.is-open .hamb-bottom {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform .1 cubic-bezier(.73, 1, .28, .08);
}

.hamburger.is-open:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-open:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px, 0, 0);
  -webkit-transition: all .35s ease-in-out;
}

/*-------------------------------*/

/*            Overlay            */

/*-------------------------------*/

.overlay {
  position: fixed;
  width: 100%;
  display: none;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(250, 250, 250, .8);
  z-index: 100;
}

.menu-icon-wrapper {
  position: fixed;
  top: 20px;
  z-index: 1000;
  display: block;
  width: 32px;
  height: 32px;
  margin-left: 15px;
  background: transparent;
  border: none;
  pointer-events: none;
  transition: 0.1s;

  button {
    &:focus, &:active {
      outline: none;
    }
  }

  svg {
    position: absolute;
    top: -33px;
    left: -33px;
    transform: scale(0.1);
    transform-origin: 0 0;
    path {
      stroke: #fff;
      stroke-width: 60px;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: transparent;
      transition: stroke-dasharray 0.5s;
      &.path1 {
        stroke-dashoffset: 5803.15px;
        stroke-dasharray: 2901.57px, 2981.57px, 240px;
      }
      &.path2 {
        stroke-dashoffset: 800px;
        stroke-dasharray: 400px, 480px, 240px;
      }
      &.path3 {
        stroke-dashoffset: 6993.11px;
        stroke-dasharray: 3496.56px, 3576.56px, 240px;
      }
    }
  }
  &.open {
    left: 0;
    svg {
      path {
        &.path1 {
          stroke-dasharray: 2901.57px, 5258.15px, 240px;
        }
        &.path2 {
          stroke-dasharray: 400px, 600px, 0px;
        }
        &.path3 {
          stroke-dasharray: 3496.56px, 6448.11px, 240px;
        }
      }
    }
  }
  .menu-icon-trigger {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    pointer-events: auto;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
  }
}
