.navbar {
  font-family: 'Raleway', sans-serif;

  * {
    font-family: 'Raleway', sans-serif;
  }
}

h1 ,h2, h3 ,h4 ,h5 {
  font-weight:400;
  font-family: 'Raleway', sans-serif;
}

.content {
  .title {
    font-family: 'Raleway', sans-serif;
  }
}