#home {
  padding-top: 20%;

  .panel-default {
    border: 0;
    border-radius: 0;
  }

  .panel-heading {
    border-radius: 0;
  }
}
