
// Fix
body {
  // Dirty fix for EL-Dialog adding padding and overflow at modals
  padding: 0 !important;
  overflow: visible !important;
}

// Vue cloak fix
[v-cloak] .v-cloak--hidden {
  display: none;
}

.no-connection {
  border: 5px solid $brand-danger;

  span.text-inner {
    background: $brand-danger;
  }
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100000;

  .loader-flex {
    width: 100%;
    height: 100%;
    display: flex;
  }

  h3 {
    margin: 0 auto;
    margin-top: 30%;
    display: inline-block;
    z-index: 1000000;
    color: #fff;
    font-weight: bold;
  }

  img {
    width: 64px;
    height: 64px;
    margin: 0 auto;
    margin-top: 30%;
    display: inline-block;
    z-index: 1000000;
  }

  svg {
    margin: 0 auto;
    margin-top: 30%;
    display: inline-block;
    z-index: 1000000;
  }

}


.text-primary {
  color: $brand-primary;
}

.text-secondary {
  color: $brand-secondary;
}

.text-success {
  color: $brand-success;
}

.text-info {
  color: $brand-info;
}

.text-danger {
  color: $brand-danger;
}

#app {

  .full_bg {
    background: #fff;
    -webkit-background-size: cover;
    -moz-background-size: cover; //background: url('/img/kroeze-bg-2.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100%;
    // height: 300px;
    // Fill bg
    top: 0;
    z-index: -100;
    left: 0;
    position: absolute;
    // No webkit
    &:after {
      content: "";
      width: 100%;
      display: inline-block;
      opacity: 0.86;
      width: 100%;
      height: 300px;
      background: $brand-primary;
      /* Old browsers */
      background: -moz-linear-gradient(-45deg, $brand-primary 100%, $brand-primary 1%);
      /* FF3.6+ */
      // background: -webkit-gradient(linear, left top, right bottom, color-stop(100%, $brand-primary), color-stop(1%, $brand-primary);
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(-45deg, $brand-primary 100%, $brand-primary 1%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(-45deg, $brand-primary 100%, $brand-primary 1%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(-45deg, $brand-primary 100%, $brand-primary 1%);
      /* IE10+ */
      background: linear-gradient(135deg, $brand-primary 100%, $brand-primary 1%);
      /* W3C */
      // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='$brand-primary ', endColorstr='$brand-primary-lighter', GradientType=1);
      /* IE6-9 fallback on horizontal gradient */
      -webkit-mask-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    }
  }

  &.fullscreen {
    background-color: $brand-primary;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  #content-wrapper {
    margin-left:$sidebar-width;
  }

  #content {

    h1,
    h2,
    h3,
    h4 {
      margin: .5em;
      font-weight: bold;
      text-align: center;
    }

    // all cols
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
      padding: 0;

    }

    .el-pagination {
      position: fixed;
      bottom: 30px;
    }

    .dashboard-heading {
      font-size: 2em;
      background-color: #333333;

      svg {
        width: 40px;
        float: left;
        margin-right: .5em;
      }

      p {
        margin-bottom: 0;
      }
    }

    .container-fluid {
      padding-left:0;
      padding-right:0;
    }


    .panel {

      h3,h4 {
         &.sub-title {
           text-align:left;
           margin-left: 150px;
         }

        &.normal {
          font-weight:100;
        }
      }

      border: 0;
      .panel-body {
        padding: 0;
      }

      .padding-top-15 {
        padding-top: 15px;
      }

      .container-fluid {
        padding-left: 0;
        padding-right: 0;
      }

      .panel-secondary {
        .panel-heading {
          background-color: #bbb141;
          color: #fff;
        }
      }

      .panel-gray {
        .panel-heading {
          background-color: #eef1f6;
          color: #48576a;
        }
      }

      .filters {
        &__selection {
          .text {
            font-weight: bold;
          }
          .el-tag {
            margin-left: .1em;
          }
          padding: 0.5em;
        }
        &__sub {
        }
      }
      [class^='filters'] {
        input[type] {
          border-radius: 0;
          text-align: center;
        }
        .btn {
          width: 100%;
          border-radius: 0;
          //height: $filters-input-height;
        }
        .el-select {
          width: 100%;
          border-radius: 0;
          //height: $filters-input-height;
        }
        .el-button {
          width: 100%;
          border-radius: 0;
          //height: $filters-input-height;
        }
        [class^='el-input'] {
          border-radius: 0;
        }
        .el-input {
          &__inner {
            //height: $filters-input-height;
          }
        }
        .el-date-editor--daterange.el-input {
          width: 100%;
        }
      }
      .panel-heading {
        border: 0;
        border-radius: 0;
        .title-icon {
          span {
            &[class^="icon-"] {
              font-size: 1.3em;
              padding: .4em;
              margin-right: .5em;
              background: rgb(236, 202, 33);
              border-radius: 100%;
              vertical-align: center;
              color: #2c2c62;
            }
          }
        }
        .btn {
          margin: 0;
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 0;
          padding-top: 0px;
          [class^="icon-"] {
            font-weight: bold;
            font-size: 1.2em;
          }
        }
      }
    }
  }
}




