//
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}

.child-view {
  transition: opacity .3s
}

.slide-left-enter, .slide-right-leave-active {

  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
  transition: opacity .3s
}

.slide-left-leave-active, .slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
  transition: opacity .3s
}