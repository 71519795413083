#login {
  margin-top: 12.5%;
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  // Links
  a {
    color: #fff;

    &:hover {
      font-weight: bold;
      text-decoration: none;
    }
  }

  //Login panel modification
  .panel {
    border: 0;
    background-color: rgba(255, 255, 255, 0);
  }
  // Logo
  .panel-heading {
    border: 0;
    background-color: rgba(255, 255, 255, 0);
    // logo
    img {
      width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  // Login button
  .btn-primary {
    background-color: #efefef;
    color: #707070;
    font-weight: bold;
    width: 100%;
  }

  // No border on login inputs
  .form-control {
    border-radius: 0;
  }

  .el-input-group__append {
    transition: all 0.1s ease-in;
    & .el-icon-check {
      color : $brand-success;
    }
  }

  .el-input-group__prepend {

    & i.fa {
      color: #333;
      transition: all 0.1s ease-in;
    }
  }

  .el-form-item__error {
    color: #ffffff;
    background: $brand-danger;
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    padding: 4px;
    position: absolute;
    top: 100%;
    left: 0;
  }

  // Errors on login
  .help-block {
    padding: 1em;
    color: #fff !important;
    background: $brand-danger;
  }

}


